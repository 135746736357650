// src/services/queries.js
import { useQuery, useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'services/useAPI';
import toast from 'react-hot-toast';
import handleError from 'utils/handleError';

import { useLoading } from 'contexts/LoadingContext';

function objectToFormData(obj, form = null, namespace = '') {
    const formData = form || new FormData();
    let hasFile = false;

    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            const formKey = namespace ? `${namespace}[${property}]` : property;

            if (obj[property] instanceof File) {
                formData.append(formKey, obj[property], obj[property].name);
                hasFile = true;
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                const [nestedFormData, nestedHasFile] = objectToFormData(obj[property], formData, formKey);
                hasFile = hasFile || nestedHasFile;
            } else if (obj[property] !== undefined) {
                formData.append(formKey, obj[property]);
            }
        }
    }

    return [formData, hasFile];
}

export const useFetchData = (endpoint, showToast = true, enabled = true) => {
    const api = useAPI();

    return useQuery({
        queryKey: [endpoint],
        queryFn: async () => {
            const { data } = await api.get(endpoint);
            return data?.results ? data.results : data;
        },
        enabled: enabled,
        onError: (error) => {
            console.log(error.message)
            if (showToast) {
                toast.error(handleError(error));
            }
        }
    });
};

export const useFetchDataId = (endpoint, id, showToast = true) => {
    const api = useAPI();

    return useQuery({
        queryKey: [endpoint, id],
        enabled: !!id,
        queryFn: async () => {
            const { data } = await api.get(`${endpoint}/${id}`);
            return data?.results ? data.results : data;
        },
        onError: (error) => {
            console.error(error)
            if (showToast) {
                toast.error(handleError(error));
            }
        }
    });
};

export const useCreateData = (endpoint) => {
    const api = useAPI();
    const { setLoading } = useLoading();

    return useMutation({
        mutationKey: endpoint,
        mutationFn: async (newObject) => {
            setLoading(true)
            const [formData, hasFile] = objectToFormData(newObject);
            const { data } = await api.post(`${endpoint}/`, formData, {
                headers: {
                    'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
                },
            });
            toast.success('Data created successfully');
            return data;
        },
        onError: (error) => {
            console.log(error.message)
            toast.error(handleError(error));
            return false
        },
        onSettled: () => setLoading(false),
    });
}

export const useUpdateData = (endpoint) => {
    const api = useAPI();

    return useMutation({
        mutationKey: endpoint,
        mutationFn: async ({ id, newData }) => {
            const [formData, hasFile] = objectToFormData(newData);
            const { data } = await api.patch(`${endpoint}/${id}/`, formData, {
                headers: {
                    'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
                },
            });
            toast.success('Data updated successfully');
            return data;
        },
        onError: (error) => {
            console.error(error);
            toast.error(handleError(error));
        }
    });
}

export const useDeleteData = (endpoint) => {
    const api = useAPI();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: endpoint,
        mutationFn: async (id) => {
            const { data } = await api.delete(`${endpoint}/${id}/`);
            toast.success('Data deleted successfully');
            return data;
        },
        onSuccess: (data, id) => {
            queryClient.invalidateQueries({
                queryKey: [endpoint],
                exact: false,
                refetchType: 'all'
            })
        },
        onError: (error) => {
            console.log(error)
            toast.error(handleError(error));
        }
    });
};


export const useDownloadData = (endpoint) => {
    const { setLoading } = useLoading();

    const api = useAPI();

    return useMutation({
        mutationKey: ['download', endpoint],
        mutationFn: async (filenameFallback = 'download.pdf') => {
            setLoading(true)
            try {
                const response = await api.get(endpoint, { responseType: 'blob' });
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);


                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                let filename = filenameFallback;
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                    filename = filenameMatch ? filenameMatch[1] : filenameFallback;
                }

                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                toast.success('File downloaded successfully!');

                return { filename };
            } catch (error) {
                const response = await api.get(endpoint);
                console.log(response)
            }
        },
        onError: (error) => {
            console.error(error);
            toast.error(handleError(error));
        },
        onSettled: () => setLoading(false),
    });
};