
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash, BsFillEyeFill } from "react-icons/bs";
import { TbFileInvoice } from "react-icons/tb";
import { API_BASE_URL } from "config";
import { downloadFile } from 'utils/downloadFile';
import toast from 'react-hot-toast';
import { Chip } from '@mui/material';
import { STATUS_CONFIG } from 'constants';

export const getColumnsData = ({ status, users, resource, setSelectedItem, setOpenDeleteDialog, shippers }) => [
    {
        field: 'order_identifier',
        headerName: 'Reference',
        description: 'Unique reference number',
        width: 85,
        editable: false,
        groupable: false,
        aggregable: false,
        type: 'string',
    },
    {
        field: 'customer_ref',
        headerName: 'Cust. Ref.',
        description: 'Customer reference number',
        width: 85,
        editable: false,
        groupable: false,
        aggregable: false,
        type: 'string',
    },
    {
        field: 'social_reason',
        headerName: 'Customer',
        minWidth: 200,
        editable: false,
        groupable: true,
        aggregable: false,
        type: 'string',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        editable: false,
        groupable: true,
        aggregable: false,
        type: 'singleSelect',
        valueOptions: status?.map((status) => ({
            value: status,
            label: status,
        })),
        renderCell: (params) => {
            const status = params.value;
            const config = STATUS_CONFIG[status] || {
                color: 'default',
                icon: null
            };
            const Icon = config.icon;

            return (
                <Chip
                    // icon={Icon && <Icon />}  // Only render icon if it exists
                    label={status}
                    color={config.color}
                    size="small"
                    variant="filled"
                />
            );
        }
    },
    // {
    //     field: 'issue_date',
    //     headerName: 'Date of Issue',
    //     editable: false,
    //     groupable: false,
    //     aggregable: false,
    //     type: 'date',
    //     valueGetter: (value, row) => {
    //         const issueDate = row?.issue_date || null;
    //         return issueDate ? new Date(issueDate) : null;
    //     },
    // },
    {
        field: 'total_amount',
        headerName: 'Invoice ($)',
        description: 'Total amount of the invoice',
        minWidth: 100,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        valueGetter: (value, row) => {
            const total_amount = row?.total_amount_invoice || 0;
            return parseFloat(total_amount);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },
    {
        field: 'total_cost_usd',
        headerName: 'Cost ($)',
        minWidth: 100,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        visibleTo: ['Administrator', 'Staff'],
        valueGetter: (value, row) => {
            const amount = row?.total_cost_usd || 0;
            return parseFloat(amount);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },
    {
        field: 'gross_product_margin',
        headerName: 'Margin ($)',
        minWidth: 100,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        visibleTo: ['Administrator', 'Staff'],
        valueGetter: (value, row) => {
            const margin = row?.gross_product_margin || 0;
            return parseFloat(margin);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div style={{ color: value < 0 ? 'red' : 'green' }}>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },
    {
        field: 'total_paid',
        headerName: 'Paid ($)',
        minWidth: 100,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        valueGetter: (value, row) => {
            const amount = row?.total_paid || 0;
            return parseFloat(amount);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },
    {
        field: 'balance',
        headerName: 'Balance ($)',
        minWidth: 150,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        valueGetter: (value, row) => {
            const amount = row?.balance || 0;
            return parseFloat(amount);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div style={{ color: value < 0 ? 'red' : 'inherit' }}>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },

    {
        field: 'total_additional_costs',
        headerName: 'Additional Costs ($)',
        minWidth: 150,
        editable: false,
        groupable: false,
        aggregable: true,
        type: 'number',
        visibleTo: ['Administrator', 'Staff'],
        valueGetter: (value, row) => {
            const amount = row?.total_additional_costs || 0;
            return parseFloat(amount);
        },
        renderCell: (params) => {
            const value = params.value;
            return (
                <div>
                    {value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            );
        }
    },

    // issue date - today
    // {
    //     field: 'days',
    //     headerName: 'Time in Days',
    //     // width: 150,
    //     editable: false,
    //     groupable: false,
    //     aggregable: true,
    //     type: 'number',
    //     valueGetter: (value, row) => {
    //         const issueDate = row?.issue_date || null;
    //         const today = new Date();
    //         const diffTime = Math.abs(today - new Date(issueDate));
    //         const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    //         return diffDays;
    //     },
    // },
    {
        field: 'etd',
        headerName: 'ETD',
        editable: false,
        groupable: false,
        aggregable: false,
        type: 'date',
        valueGetter: (value, row) => {
            const etd = row?.etd || null;
            return etd ? new Date(etd + "T12:00:00") : null;
        },
    },
    // delivery info . eta (date)
    {
        field: 'eta',
        headerName: 'ETA',
        editable: false,
        groupable: false,
        aggregable: false,
        type: 'date',
        valueGetter: (value, row) => {
            const eta = row?.eta || null;
            return eta ? new Date(eta + "T12:00:00") : null;
        },
    },
    // link to download the invoice
    {
        field: 'invoice',
        headerName: 'Invoice',
        type: 'actions',
        editable: false,
        groupable: false,
        aggregable: false,
        visibleTo: ['Client Administrator', 'Client Staff'],
        getActions: (params) => {
            const { id } = params.row;
            return [
                <GridActionsCellItem
                    icon={<TbFileInvoice />}
                    color="primary"
                    label="Edit"
                    onClick={async () => {
                        try {
                            await downloadFile(`${API_BASE_URL}/order/generate-pdf-commercial-invoice/${id}/`);
                        } catch (error) {
                            const errorData = await error.json();
                            console.error(errorData);
                            toast.error(errorData.error || 'Failed to download the invoice.');
                        }
                    }}
                />,
                <GridActionsCellItem
                    icon={<BsFillEyeFill />}
                    color="primary"
                    label="View"
                    component={Link}
                    to={`/admin/${resource}/detail/${id}`}
                />,
                <GridActionsCellItem
                    icon={<BsPencilSquare />}
                    color="primary"
                    label="Edit"
                    component={Link}
                    to={`/admin/${resource}/edit/${id}`}
                />,
            ]
        }
    },

    {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        editable: false,
        groupable: false,
        aggregable: false,
        visibleTo: ['Administrator', 'Staff'],
        getActions: (params) => {
            const { id } = params.row;
            return [
                <GridActionsCellItem
                    icon={<BsPencilSquare />}
                    color="primary"
                    label="Edit"
                    component={Link}
                    to={`/admin/${resource}/edit/${id}`}
                />,
                <GridActionsCellItem
                    icon={<BsFillEyeFill />}
                    color="primary"
                    label="View"
                    component={Link}
                    to={`/admin/${resource}/detail/${id}`}
                />,
                <GridActionsCellItem
                    color="primary"
                    icon={<BsTrash />}
                    label="Delete"
                    onClick={() => {
                        setSelectedItem(params.row.id); // Set the selected item
                        setOpenDeleteDialog(true); // Open the dialog
                    }}
                />,
            ];
        }
    },
    {
        field: 'assigned_to',
        headerName: 'Assigned To',
        editable: false,
        groupable: true,
        aggregable: false,
        minWidth: 150,
        valueGetter: (value, row) => {
            const user = users?.find(user => user.id === value);
            return user?.name || null;
        }
    },
    // shipper
    {
        field: 'shipper',
        headerName: 'Shipper',
        editable: false,
        groupable: true,
        aggregable: false,
        minWidth: 250,
        type: 'singleSelect',
        valueOptions: shippers?.map((shipper) => ({
            value: shipper.id,
            label: shipper.social_reason,
        })),
    },
    // suppliers (list of strings)
    {
        field: 'suppliers',
        headerName: 'Suppliers',
        editable: false,
        groupable: true,
        aggregable: false,
        minWidth: 250,
        type: 'string',
        valueGetter: (value, row) => {
            return row?.suppliers?.join(', ') || null;
        },
    },
];