import React from "react";
import { useFetchData } from 'services/queries';
import { useEffect, useState } from "react";
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';
import ViewControls, { ViewType, getViewSettings } from './ViewControls';
import { getColumnsData } from './columns';
import { Tabs, Tab } from '@mui/material';
import Analytics from './Analytics';

const DataGridOrders = ({ data, users, status, resource, resource_name, shippers }) => {
    const [viewType, setViewType] = React.useState(ViewType.Basic);
    const [userColumnVisibility, setUserColumnVisibility] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const handleViewTypeChange = (newViewType) => {
        setViewType(newViewType);
        if (newViewType !== ViewType.All) {
            const viewSettings = getViewSettings(newViewType);
            const newVisibility = { ...userColumnVisibility };
            Object.keys(viewSettings).forEach(key => {
                delete newVisibility[key];
            });
            setUserColumnVisibility(newVisibility);
        }
    };

    const handleColumnVisibilityModelChange = (newModel) => {
        if (viewType === ViewType.All) {
            setUserColumnVisibility(newModel);
        } else {
            setViewType(ViewType.All);
            setUserColumnVisibility(newModel);
        }
    };

    const columnVisibilityModel = React.useMemo(() => {
        return viewType === ViewType.All
            ? userColumnVisibility
            : {
                ...userColumnVisibility,
                ...getViewSettings(viewType)
            };
    }, [viewType, userColumnVisibility]);

    const columnsData = getColumnsData({ status, users, resource, setSelectedItem, setOpenDeleteDialog, shippers });
    const columns = useRoleBasedColumns(columnsData);

    return (
        <>
            <ViewControls
                viewType={viewType}
                onViewTypeChange={handleViewTypeChange}
            />
            <Box>
                <DataGridPremium
                    rows={data}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    pagination
                    pageSizeOptions={[25, 50, 100]}
                    initialState={{
                        density: 'comfortable',
                        pinnedColumns: { left: ['actions', 'invoice'] },
                        pagination: { paginationModel: { pageSize: 25 } },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterExcludeHiddenColumns: false,
                            },
                        },
                    }}
                    autosizeOptions={{
                        columns: ['reference', 'customer_ref', 'buyer', 'issue_date', 'total_amount', 'status', 'days'],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    slots={{
                        toolbar: ToolbarCrud
                    }}
                    slotProps={{
                        toolbar: {
                            resource,
                            name: resource_name
                        }
                    }}
                />
            </Box>
            <DeleteConfirmDialog
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                selectedItem={selectedItem}
                resource={resource}
            />
        </>
    );
};

const ImporterView = () => {
    const resource = 'order/sales-order';
    const resource_name = 'Sales Order';
    const { auth, authLoading } = useAuth();
    const [status, setStatus] = useState(null);
    const [currentTab, setCurrentTab] = React.useState(0);


    const { data: users, error: errorUsers, isLoading: isLoadingUsers } = useFetchData('authentication/users', true);
    const { data: shippers, error: errorShippers, isLoading: isLoadingShippers } = useFetchData('shipper', true);

    // Fetch orders data based on the selected tab
    const { data: inProgressOrders, error: errorInProgress, isLoading: isLoadingInProgress } = useFetchData(
        'order/report-sales-order/?status_not_in=Delivered,Discontinued',
        true,
        currentTab === 0
    );

    // Replace allOrders fetch with separate fetches for different purposes
    const { data: allOrders, error: errorAllOrders, isLoading: isLoadingAllOrders } = useFetchData(
        'order/report-sales-order',
        true,
        currentTab === 1
    );

    useEffect(() => {
        if (allOrders) {
            const statusList = allOrders.map(item => item.status);
            const uniqueStatus = [...new Set(statusList)];
            setStatus(uniqueStatus);
        }
        else if (inProgressOrders) {
            const statusList = inProgressOrders.map(item => item.status);
            const uniqueStatus = [...new Set(statusList)];
            setStatus(uniqueStatus);
        }
    }, [allOrders, inProgressOrders]);


    if (
        isLoadingUsers || isLoadingShippers ||
        (currentTab === 0 && isLoadingInProgress) ||
        (currentTab === 1 && isLoadingAllOrders)
    ) return <div>Loading...</div>;

    if (
        errorUsers || errorShippers ||
        (currentTab === 0 && errorInProgress) ||
        (currentTab === 1 && errorAllOrders)
    ) return <div>Error!</div>;

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
            >
                <Tab label="In Progress" />
                <Tab label="All Orders" />
                <Tab label="Analytics" />
            </Tabs>
            <Card extra={'w-full h-full bg-white p-3'} style={{ borderRadius: 0 }}>
                {currentTab === 0 && (
                    <DataGridOrders
                        data={inProgressOrders}
                        users={users}
                        status={status}
                        resource={resource}
                        resource_name={resource_name}
                        shippers={shippers}
                    />
                )}
                {currentTab === 1 && (
                    <DataGridOrders
                        data={allOrders}
                        users={users}
                        status={status}
                        resource={resource}
                        resource_name={resource_name}
                        shippers={shippers}
                    />
                )}
                {currentTab === 2 && (
                    <Analytics currentTab={currentTab} />
                )}
            </Card>
        </>
    );
};

export default ImporterView;